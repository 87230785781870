<div class="main-layout">
    <app-header-web [headerText1]="header1" [productName]="product"></app-header-web>
    <div class="content-layout">
        <div class="feature-layout">
            <div class="block">
                <div class="content">Система технического зрения «Маска» (СТЗ «Маска») - автоматизированная система контроля и оперативного оповещения о нарушениях применения средств индивидуальной защиты (СИЗ) - защитных масок. 
                    <ul>
                        <li>СТЗ «Маска» решает задачу детекции объекта заданного класса при помощи системы, основанной на искусственной нейронной сети.</li>
                        <li>Нейросетевой алгоритм извлекает информацию из видеопотока и интерпретирует ее в условиях вариативности объекта или сцены. </li>
                        <li>Система базируется на алгоритмах искусственного интеллекта, адаптивна, способна самостоятельно обобщать знания. </li>
                        <li>Объекты могут быть представлены в произвольных ракурсах, зафиксированы в различных условиях освещения. </li>
                        <li>Источники данных: IP-камеры видимого и ИК- спектра.</li>
                        <li>Средняя задержка обработки потока (для платформ на базе процессоров Core i3 11-го поколения): не более 1000 мс.</li>
                        <li>Оповещение о выявленных нарушениях: сообщение в окне приложения, звуковой сигнал.</li>
                        <li>Стоимость 200 000 рублей.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <div class="title">Руководства</div>
                <div class="content">
                    <a href="../../../assets/docs/maska/Description.pdf" download="Description.pdf">Краткое описание</a><br>
                    <a href="../../../assets/docs/maska/User_guide.pdf" download="User_guide.pdf">Руководство пользователя</a><br>
                    <a href="../../../assets/docs/maska/Functional_characteristics.pdf" download="Functional_characteristics.pdf">Функциональные характеристики</a><br>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>

