<app-nav id="top-anchor"></app-nav>
<div class="main-layout">

    <app-header [headerText1]="header1" [headerText2]="header2" [headerText3]="header3"></app-header>

    <div class="content-layout">
        <div class="intro">
            <div class="intro-content">
                <app-corner></app-corner>
                <div class="title">Компания</div>
                <div class="desc-text">
                    Созданная в 2005 году компания "Телеформ ИС" объединила специалистов, имеющих на тот момент более
                    чем
                    пятнадцатилетний опыт разработки и внедрения критически важных для заказчиков автоматизированных
                    систем (АС) в банковской и финансовой сферах, промышленности и госсекторе.
                </div>
                <div class="content">
                    Основным направлением деятельности компании является создание высокотехнологичных
                    систем и комплексов программного обеспечения (ПО) общего и специального назначения для решения
                    широкого круга задач, включая автоматизацию процессов управления, поддержки принятия решений и
                    защиты информации.</div>
                <div class="content">
                    Компания "Телеформ ИС" выполняет проекты для предприятий государственной корпорации (ГК) Росатом,
                    Сберегательного банка (СБ) РФ, предприятий Министерства обороны, телекоммуникационных и
                    энергетических компаний, имеет опыт совместной работы с институтами Российской Академии Наук и
                    высшими
                    учебными заведениями.
                </div>
                <div class="second-title">Основные услуги</div>
            </div>
        </div>
        <div class="feature-layout">
            <div class="features">
                <div class="block">
                    <div>
                        Создание систем искусственного интеллекта и обработки больших данных;
                    </div>
                    <div>
                        Разработка специального программного обеспечения на заказ;
                    </div>
                </div>
                <div class="block">
                    <div>
                        Разработка и сопровождение баз данных любой сложности;
                    </div>
                    <div>
                        Разработка мобильных приложений;
                    </div>
                    <div>
                        Разработка систем дополненной и виртуальной реальности;
                    </div>
                </div>
                <div class="block">
                    <div>
                        Проведение научно-исследовательских и опытно-конструкторских работ (НИОКР) в области
                        информационных технологий, автоматизации и робототехники.
                    </div>

                </div>
            </div>

        </div>
        <div class="add-layout">
            <div class="add-content">
                <div class="content">
                  Компания «Телеформ ИС» включена в реестр аккредитованных ИТ-компаний Минцифры России, является членом <a href=" https://arppsoft.ru/members/11792/" target="__blank">Ассоциации</a> Разработчиков Программных Продуктов (АРПП) «Отечественный софт», имеет все необходимые сертификаты и лицензии, в том числе лицензии ФСБ.</div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
