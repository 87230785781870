<div class="main-layout">
    <app-header-web [headerText1]="header1" [headerText2]="header2" [productName]="product"></app-header-web>
    <div class="content-layout">
        <div class="feature-layout">

            <div class="intro"><strong>Программный продукт BI-Сфера </strong> внесен в состав <a
                    href="https://reestr.digital.gov.ru/reestr/65212/" target="__blank">Реестра отечественного ПО </a>
                на основании приказа Минкомсвязи России от 18 марта 2016 года.</div>
            <div class="block">
                <div class="title"> BI-Сфера решает следующие задачи</div>
                <div class="content">
                    <ul>
                        <li>создание и настройка систем ключевых показателей эффективности;</li>
                        <li>мониторинг, анализ и корректировка целей;</li>
                        <li>интерактивная визуализация показателей;</li>
                        <li>моделирование различных бизнес-ситуаций в единой информационной среде;</li>
                        <li>проведение оперативного анализа по нестандартным запросам;</li>
                        <li>проведение оперативного анализа по нестандартным запросам;</li>
                        <li>мониторинг бизнес-процессов;</li>
                        <li>сбор, обобщение, хранение и обработка данных из смежных систем.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <div class="title"> Функциональные возможности</div>
                <div class="content">
                    <ul>
                        <li>создание, ведение и мониторинг исполнения стратегии организации в виде информационных
                            панелей;</li>
                        <li>ведение дерева целей, сводов, карт и системы показателей;</li>
                        <li>мониторинг исполнения карт показателей сотрудников и сводов подразделений;</li>
                        <li>анализ, моделирование и прогнозирование;</li>
                        <li>многомерный (OLAP) анализ информации витрин данных;</li>
                        <li>мощная система отчетов.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <img alt="Here is a picture" style="margin: 25px 0;" src="../../../assets/images/BI-sphere.png">
            </div>
            <div class="block">
                <div class="title">Защита информации</div>
                <div class="content">Предлагаемое решение подготовлено к сертификации ФСТЭК и ФСБ для работы с
                    конфиденциальной информацией и информацией составляющей государственную тайну по классам
                    защищенности 1Г и 1Б.</div>
            </div>
            <div class="block">
                <div class="title">Предлагаемая технологическая платформа</div>
                <div class="content"><strong>BI-Сфера</strong> разработана с применением свободно распространяемых программных продуктов
                    с открытым исходным кодом, являющихся лидерами в своих направлениях. В качестве технологической
                    платформы была выбрана Java EE. Данная технологическая платформа позволяет BI-Сфера функционировать
                    на любой операционной системе, в том числе и на сертифицированной операционной системе Astra Linux
                    Special Edition.</div>
            </div>

            <div class="block">
                <div class="title">Руководства</div>
                <div class="content">
                    <a  href="../../../assets/docs/bi-sphere/user-sopr.pdf" download="user-sopr.pdf" >Регламент по сопровождению пользователей</a><br>
                    <a href="../../../assets/docs/bi-sphere/admin-ruk.pdf" download="admin-ruk.pdf" target="__blank">Руководство администратора</a><br>
                    <a href="../../../assets/docs/bi-sphere/oper-ruk.pdf" download="oper-ruk.pdf"  target="__blank">Руководство оператора</a>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>