<div class="header-wrap">
    <div class="header-content">
        <div class="logo-wrap" (click)="getHomePage()">
            <div class="logo"></div>
            <div class="logo-text"></div>
        </div>
        <div class="product-name" *ngIf="productName">{{productName}}</div>
    </div>
</div>
<div class="header-layout">
    <div class="main-title">
        <div class="text-wrap">
            <div class="text">
               <div class="main-text" *ngIf="headerText1"> {{headerText1}}</div> <br>
                <div class="desc-text" *ngIf="headerText2">{{headerText2}} </div>             
            </div>
        </div>
    </div>
</div>
