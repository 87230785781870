<div class="main-layout">
    <app-header-web [headerText1]="header1" [headerText2]="header2" [productName]="product"></app-header-web>
    <div class="content-layout">
        <div class="feature-layout">

            <div class="intro"><strong>Программный продукт Библио-Сфера </strong> внесен в состав <a
                    href="https://reestr.digital.gov.ru/reestr/88052/" target="__blank">Реестра отечественного ПО</a> на
                основании приказа Минкомсвязи России от 06 сентября 2016 года.</div>
            <div class="block">
                <div class="title"> Электронная библиотека</div>
                <div class="content">
                    Важное место в успешной организации работы предприятия занимают автоматизированные информационно-библиотечные системы, которые призваны обеспечивать управление процессами библиотечных фондов, полное и оперативное информационное обеспечение сотрудников организации.
                </div>
                <div class="content">
                    Автоматизированная система «Библио-СФера» - это web-приложение, которое обеспечивает накопление электронных документов, их хранение и предоставление доступа к ним пользователей через информационные ресурсы предприятия.
                </div>
            </div>
            <div class="block">
                <div class="title"> Функциональные возможности</div>
                <div class="content">
                    <ul>
                        <li>формирование защищенного централизованного хранилища данных организации – электронный архив, электронные библиотеки и репозитории документов;</li>
                        <li>полное и оперативное информационное обеспечение сотрудников организации;</li>
                        <li>организация справочно-поискового аппарата по всему фонду электронных документов;</li>
                        <li>разграничение доступа сотрудников к хранимым данным вплоть до отдельного документа;</li>
                        <li>учет и мониторинг движения и местонахождения документов в организации;</li>
                        <li>повышение качества контроля исполнительской дисциплины - автоматический мониторинг сроков прохождения электронных документов и выполнения задач;</li>
                        <li>информирование сотрудников предприятия о новых поступлениях в электронный фонд;</li>
                        <li>поддержка «бумажных» технологий;</li>
                        <li>совместимость с российскими и международными форматами представления библиографических данных RUSMARC/UNIMARC/MARC21;</li>
                        <li>поддержка многоязычных форматов на основе UNICODE;</li>
                        <li>сокращение как временных, так и материальных затрат на организацию хранения данных организации, разграничения доступа к данным, а также на контроль целостности данных обеспечения недопустимости несанкционированного доступа;</li>
                        <li>сокращение как временных, так и материальных затрат на информационное обеспечение сотрудников.</li>
                    </ul>
                </div>
            </div>

            <div class="block">
                <div class="title">Руководства</div>
                <div class="content">
                    <a href="../../../assets/docs/biblio-sphere/biblio-user-sopr.pdf" download="biblio-user-sopr.pdf">Регламент по сопровождению пользователей</a><br>
                    <a href="../../../assets/docs/biblio-sphere/biblio-ruk-adm.pdf" download="biblio-ruk-adm.pdf">Руководство администратора</a><br>
                    <a href="../../../assets/docs/biblio-sphere/biblio-ruk-oper.pdf" download="biblio-ruk-oper.pdf">Руководство оператора</a>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
