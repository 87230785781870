<app-nav id="top-anchor"></app-nav>
<div class="main-layout">
    <app-header [headerText1]="header1" [headerText2]="header2" [headerText3]="header3"></app-header>
    <div class="content-layout">
        <div class="intro">
            <div class="intro-content">
                <app-corner></app-corner>
                <div class="title">Исследования и разработки</div>
                <div class="content">Основной приоритет компании «Телеформ ИС» - решение прикладных задач цифровой
                    экономики. Многолетний успешный опыт компании позволяет реализовывать проекты любого масштаба как
                    для коммерческих, так и государственных заказчиков.
                </div>
                <div class="content">
                    Разработки «Телеформ ИС» обеспечивают клиентам и партнерам конкурентные преимущества благодаря
                    повышению научно-технического инновационного уровня продукции. «Телеформ ИС» выполнены десятки
                    научно-исследовательских и опытно-конструкторских работ (НИОКР).
                </div>
                <div class="content">
                    Компанией осуществляется полный цикл коммерциализации инновационных проектов – от идеи до успешного
                    внедрения.
                </div>
            </div>
        </div>
        <div class="sub-header">
            <div class="content">Основные направления</div>
        </div>
        <div class="feature-layout">
            <div class="block">
                <div class="title">Системы искусственного интеллекта и больших данных как инструмент анализа и
                    рефакторинга открытого кода</div>
                <div class="content">
                    Работы по созданию инструментов анализа и рефакторинга кода компонентов систем комплексов ПО с
                    открытым исходным кодом ведутся с целью повышения уровня автоматизации процессов использования
                    открытого ПО. В них задействован имеющийся у компании опыт по моделированию архитектуры ПО и
                    рефакторингу, но теперь уже центр внимания переносится на применение методов обучения машин и
                    искусственного интеллекта. При этом для сбора, хранения и анализа огромного количества образцов
                    кода, используемых для обучения искусственных нейронных сетей применяются СУБД Apache Cassandra и ее
                    аналоги.
                </div>
            </div>
            <div class="block">
                <div class="title">Системы для видеоконференций и обучающие платформы</div>
                <div class="content">
                    <strong>Проект онлайн-школа: </strong> разработка отечественного коммуникационного программного для
                    универсального защищенного обмена информацией посредствам видеоконференц-связи и обмена сообщениями
                    для использования в образовательном процессе.
                </div>
                <div class="content">
                    Данная система предназначена для использования в том числе для дистанционного обучения,
                    реализованная на базе свободно распространяемого сервера ВКС с открытым кодом из проекта Apache
                    OpenMeetings.
                </div>
            </div>
            <div class="block">
                <div class="title">Системы управления данными </div>
                <div class="content">
                    Работы по созданию систем управления данными ведутся по двум направлениям:
                </div>
                <ul>
                    <li>создание российского аналога СУБД Apache Cassandra - Руссандра, допускающего его
                        использование при создании АСЗИ и применение на объектах критической информационной
                        инфраструктуры (КИИ);
                    </li>
                    <li>разработка на основе аналога СУБД Apache Cassandra решений по унификации и управлению
                        данными с применением методов обучения машин и искусственного интеллекта.</li>
                </ul>

                <div class="content">
                    <strong>Системы оперативной аналитической обработки данных: </strong> работы по созданию систем
                    оперативной аналитической обработки «больших данных» для управления распределенными хранилищами с
                    быстрыми операциями чтения/записи; их интеграции с популярными поисковыми системами типа Apache SOLR
                    / Elasticsearch и аналитическими фреймворками типа Apache Spark / Apache Flink и им подобными;
                    обеспечении возможности поддержки множества разных моделей данных.
                </div>
                <div class="content">
                    <strong>Системы виртуальной интеграции данных: </strong> работы по созданию систем виртуальной
                    интеграции данных базируются на результатах выполненной в 2015-2017 годах НИР, в рамках которой были
                    изучены технологии виртуализации баз данных, реализованные в ряде популярных программных
                    продуктов.
                </div>
                <div class="content">
                    Разработка серверов системы виртуализации данных осуществляется на основе комплекса открытого ПО
                    JBoss Teiid. В качестве IMDB используются СУБД Apache Cassandra и ее аналоги, в качестве IMDG –
                    комплекс открытого ПО Infinispan.
                </div>
                <div class="content">
                    Одна из задач проекта – обеспечение возможности поддержки в слое виртуализации графовых моделей
                    данных, связывающих объекты данных, которые физически могут располагаться в множестве разных
                    источников информации, в общем случае созданных независимо друг от друга.
                </div>
            </div>
            <div class="block">
                <div class="title">Проект по созданию современной технологии сжатия данных</div>
                <div class="content">
                    «Телеформ ИС» совместно с компанией «Петарх» разрабатывает уникальное программное обеспечение для оптимизации хранения и передачи информации за счет альтернативной технологии сжатия данных с помощью широкополосной развёртки.
                </div>
                <div class="content">
                    Данное программное обеспечение планируется внедрять в системы передачи видеопотока для существенной оптимизации канала передачи данных.
                </div>
            </div>
            <div class="block">
                <div class="title">Системы промышленного и бытового Интернета вещей</div>
                <div class="content">
                    Работы по созданию систем управления распределенными комплексами контрольно-измерительного
                    оборудования включают освоение общедоступных открытых платформ «интернета вещей» и разработку
                    решений для «умных» домов, городов, тепло-энергосетей, разнообразных АСУ ТП и т.п.
                </div>
            </div>
            <div class="block">
                <div class="title">Системы управления процессами</div>
                <div class="content">
                    Работы по созданию систем управления процессами базируются на освоении и использовании комплексов ПО
                    с открытым кодом, включающих средства визуального проектирования процессов и средства интерпретации
                    процессов. Обеспечение возможности автоматического преобразования моделей процессов в исполняемые
                    программы для компьютеров.
                </div>
            </div>
            <div class="block">
                <div class="title">Проект Скада</div>
                <div class="content">
                    Задачи данного проекта предполагают сквозную автоматизацию технологических процессов предприятия
                    молочного животноводства. Технологические процессы данного класса предприятий предполагают множество
                    различных операций с разной степенью вариативности, включая высокую, за счет множества внешних
                    факторов. Следствием данной особенности является многообразие технических реализации автоматизации
                    отдельных этапов технологических и служебных процессов на предприятии. При этом задачами сквозной
                    автоматизации является обеспечение «оркестрации» программно-аппаратных решений, автоматизирующих
                    отдельные операции и/или этапы технологического процесса с возможностью их адаптации в рамках
                    конфигурирования системы с учетом особенностей технологических процессов и их дальнейшего
                    преобразования на всех этапах жизненного цикла автоматизируемого предприятия.
                </div>
            </div>
            <div class="block">
                <div class="title">Системы медицинской диагностики</div>
                <div class="content">
                    <strong>Проект Wave: </strong> программный комплекс нейросетевой обработки больших данных для
                    распознавания результата ИХА экспресс-теста, предназначенный для интерпретации результата
                    использования аптечных тестовых полосок для определения беременности или фертильного периода. В
                    состав комплекса входит облачный сервис и мобильное приложение. Разработка проводилась при
                    финансовой поддержке Федерального государственного бюджетного учреждения "Фонд содействия развитию
                    малых форм предприятий в научно-технической сфере".
                </div>
                <div class="content">
                    <strong>Проект Маска: </strong> автоматизированная система решает задачу оперативного информирования
                    ответственных лиц медицинских и иных учреждений о фактах отсутствия или неправильного использования
                    средств индивидуальной защиты с целью обеспечения возможности немедленного устранения нарушений для
                    снижения риска заражения окружающих лиц коронавирусной и иными инфекциями, распространяющихся
                    воздушно-капельным путем.
                </div>
            </div>
            <div class="block">
                <div class="title">Системы имитационного моделирования</div>
                <div class="content">
                    <strong>«Модель-Сфера» </strong> осуществляет процессы имитационного моделирования боевых и охранных действий. Система построена на принципах многоагентных систем, обеспечивает должностных лиц органов военного управления всех уровней (тактический, оперативно-тактический, оперативный, стратегический) инструментальными возможностями комплексного имитационного моделирования оперативных и тактических боевых задач, с применением всех средств вооружения, в том числе вероятного противника, на электронных картах местности в пространственно-временном диапазоне как в учебных, так и в прикладных целях. 
                </div>
                <div class="content">
                    <strong>Модуль «Модель-Сфера БД» </strong> предназначен для автоматизации: оперативного планирования боевых действий вооруженных сил противоборствующих сторон, предварительной оценки принятых решений на ведение боевых действий, оценки эффективности действий вооруженных сил участников конфликта, обоснования достаточности характеристик и способов применения вооружения, получения результатов эффективности боевого применения сил и средств.
                </div>
                <div class="content">
                    <strong>Модуль «Модель-Сфера ОБМ»</strong> предназначен для автоматизации: планирования применения сил, проверки принятых решений на проведение боевых мероприятий, оценки эффективности принятых решений и тактических приемов, моделирования проведения штабных учений, сокращения объемов работ и времени на планирование, а также  повышение качества оценки эффективности подразделений и их взаимодействия. 
                </div>
                <div class="content">
                    <strong>Модуль «Модель-Сфера ОВО»</strong> предназначен для автоматизации: планирования применения подразделений охраны важных объектов государственного значения, а также территориально-распределенных объектов, проверки принятых решений на обеспечение охраны и противодиверсионных действий, обоснования достаточности характеристик и способов применения систем технической защиты, средств вооружения и тактических приемов. Модуль позволяет сократить время и объемы работ на планирование охраны, повысить качество оценки взаимодействия подразделений охраны и защиты. 
                </div>
            </div>
            <div class="block">
                <div class="title">Искусственные нейронные сети</div>
                <div class="content">
                    Решения компании «Телеформ ИС» применяются в задачах компьютерного зрения, выявления событий,
                    аномалий, оценки параметров объектов и процессов, предиктивного анализа.
                </div>
                <div class="content">
                    Системы «Телеформ ИС» используются в управлении робототехническими комплексами, обеспечении
                    безопасности транспортных средств, интерпретации результатов химических анализов, контроле качества
                    продукции, прогнозировании событий, оптимизации процессов и принятия решений.
                </div>
            </div>
            <div class="block">
                <div class="title">Робототехника</div>
                <div class="content">
                    Программная платформа управления робототехническими комплексами R-BPMControl позволяет решать задачи
                    организации и исполнения бизнес-процессов роботизированных программно-аппаратных комплексов с
                    возможностью делегирования этапов исполнения внешним исполнителям (оборудованию и программному
                    обеспечению посредством программных интерфейсов) на основе методики моделирования бизнес-процессов в
                    виде блок-схем в нотации BPMN 2.0.
                </div>
                <div class="content">
                    Система R-TBuilder предоставляет возможность автоматического построения траекторий
                    роботов-манипуляторов на основе трехмерных моделей обрабатываемых объектов. Решение позволяет
                    осуществлять унифицированное управление множеством различных манипуляторов в комплексе с
                    периферийными устройствами.
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>

</div>