<app-nav id="top-anchor"></app-nav>
<div class="main-layout">
  <app-header [headerText1]="header1" [headerText2]="header2" [headerText3]="header3"></app-header>
  <div class="content-layout">
    <div class="intro">
      <div class="intro-content">
        <app-corner></app-corner>
        <div class="title">Программное обеспечение, включенное в единый реестр российских программ для ЭВМ и баз данных</div>
        <div class="content">
        </div>

        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none"  href="https://teleformis.ru/web/guest/modulstavkaplus">Модуль видеоаналитики Ставка+</a></div>
        </div>
        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none" href="https://teleformis.ru/web/guest/maska">Система технического зрения "Маска" (СТЗ "Маска")</a></div>
        </div>
        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none" href="https://teleformis.ru/web/guest/sokb-stavka">Ставка+</a></div>
        </div>
        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none" target="_blank" href="http://bss-soft.ru/index.php?option=com_zoo&view=item&layout=item&Itemid=537&lang=ru">вШколе</a></div>
        </div>
        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none" href="https://teleformis.ru/web/guest/docu-sphere">Доку-Сфера+</a></div>
        </div>
        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none" href="https://teleformis.ru/web/guest/sistema-upravleniya-proektami">Проект-Сфера</a></div>
        </div>
        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none" href="https://teleformis.ru/web/guest/trening-sphere">Тренинг-Сфера</a></div>
        </div>
        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none" href="https://teleformis.ru/web/guest/korporativnyj-portal">ИнтраСфера</a></div>
        </div>
        <div class="block">
          <div class="content"><a style="font-size: 22px; color: #4f4f4f; text-decoration:none" href="https://teleformis.ru/web/guest/bi-sphere">BI-Sphere</a></div>
        </div>
    </div>
  </div>
  </div>
  <app-footer></app-footer>

</div>
