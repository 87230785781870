import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maska',
  templateUrl: './maska.component.html',
  styleUrls: ['./maska.component.scss']
})
export class MaskaComponent implements OnInit {

  header1: string = 'Система технического зрения «Маска» ';
  product: string = 'СТЗ «Маска»'

  constructor() { }

  ngOnInit(): void {
  }

}
