import {AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.scss']
})
export class SoftwareComponent implements AfterViewInit {

  header1: string = 'Разработка, внедрение и поддержка ';
  header2: string = 'высокотехнологичных систем и ';
  header3: string = 'комплексов программного обеспечения';

  constructor() { }

  ngAfterViewInit(): void {
    const anchor = document.querySelector('#top-anchor');
    anchor?.scrollIntoView();
  }

}
