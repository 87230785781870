<app-nav ></app-nav>
<div class="main-layout">   
    <div class="head-container container">
        <div class="text-wrap">
            <ng-container *ngIf="isAppeared[0]">
                <div class="text" @appear >Полный цикл коммерциализации<br>инновационных проектов –<br>от
                    идеи до успешного внедрения
                </div>
                <button class="detail" @fade (click)="getInfoPage()" >Подробнее</button>
            </ng-container>
            <div *ngIf="isAppeared[1]">
                <div class="text" @appear >Создание программного обеспечения<br> высокотехнологичных систем<br>и комплексов
                </div>
                <button class="detail" @fade (click)="getInfoPage()" >Подробнее</button>
            </div>
            <ng-container *ngIf="isAppeared[2]">
                <div class="text" @appear >Исследования в области<br>информационных технологий<br>и робототехники
                </div>
                <button class="detail" @fade (click)="getResearchPage()" >Подробнее</button>
            </ng-container>
            <ng-container *ngIf="isAppeared[3]">
                <div class="text" @appear >Компания включена в реестр<br>аккредитованных ИТ-компаний<br>Минцифры России
                </div>
                <button class="detail" @fade (click)="getInfoPage()">Подробнее</button>
            </ng-container>
        </div>
    </div>
</div>