import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-sphere',
  templateUrl: './project-sphere.component.html',
  styleUrls: ['./project-sphere.component.scss']
})
export class ProjectSphereComponent implements OnInit {

  header1: string = 'Проект-Сфера';
  header2: string = 'Система управления проектами и программами';
  product: string = 'Проект-Сфера'

  constructor() { }

  ngOnInit(): void {
  }

}
