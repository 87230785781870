import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { appearAnimation, fadeAnimation } from 'src/app/shared/utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [appearAnimation, fadeAnimation]
})
export class HomeComponent implements OnInit {

  isAppeared: boolean[] = [
    true, false, false, false
  ];
  intervalStream: Subscription;

  constructor(private router: Router) {
    this.intervalStream = interval(6000).subscribe(() => {
      for (let i = 0; i < this.isAppeared.length; i++) {
        if (this.isAppeared[i]) {
          if (i + 1 < 4) {
            this.isAppeared[i + 1] = true;
          } else {
            this.isAppeared[0] = true;
          }
          this.isAppeared[i] = false;
          break;
        }
      }
    });
   }

  ngOnInit(): void {
 
  }

  ngOnDestroy(): void {
    if (this.intervalStream){
      this.intervalStream.unsubscribe();
    }
  }

  getInfoPage() {
    this.router.navigate(['/info']);
  }
  getResearchPage() {
    this.router.navigate(['/research']);
  }

}
