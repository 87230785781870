import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements AfterViewInit {
  header1: string = 'Развитие цифровых технологий';
  header2: string = 'для решения ключевых задач';
  header3: string = 'эффективного использования данных';

  constructor() { }

  ngAfterViewInit(): void {
    const anchor = document.querySelector('#top-anchor');
    anchor?.scrollIntoView();
  }

}
