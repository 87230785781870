<app-nav id="top-anchor"></app-nav>
<div class="contacts-wrap">   
    <google-map #map height="100vh" width="70vw" [center]="center" [zoom]="zoom">
        <map-marker [position]="markerPosition"></map-marker>
    </google-map>
    <div class="feedback">
        <div>
            <div class="address">115114, Москва, Дербеневская наб., д. 11, корп. А </div>
            Тел.: +7 (495) 783 2056, +7 (495) 909 2800<br>
            Факс.: +7 (495) 909 2830<br>
            Email: tfinfo@teleformis.ru<br>
        </div>
        <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
            <div class="grid-contacts">
                <input placeholder="*Имя" type="text" formControlName="firstname" class="half" >
                <input placeholder="Фамилия" type="text" formControlName="lastname" class="half">
            </div>
            <input placeholder="*Компания" type="text" formControlName="company" class="whole">
            <input placeholder="Должность" type="text" formControlName="position" class="whole">
            <div class="grid-contacts">
                <input placeholder="*Телефон" type="text" formControlName="phone" class="half">
                <input placeholder="*E-mail" type="text" formControlName="email" class="half">
            </div>
            <textarea placeholder="Чем мы можем Вам помочь?" type="text" formControlName="content" class="whole"
                rows="5"></textarea>
            <div class="whole error" [class.visible]="isFeedbackError" >Проверьте корректность заполнения полей</div>
            <div class="whole success" [class.visible]="isSent" >Ваше сообщение отправлено</div>
            <button type="submit" class="whole btn" >Отправить</button>
        </form>
    </div>
</div>