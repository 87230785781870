import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intra-sphere',
  templateUrl: './intra-sphere.component.html',
  styleUrls: ['./intra-sphere.component.scss']
})
export class IntraSphereComponent implements OnInit {

  header1: string = 'Корпоративный портал ИнтраСфера';
  header2: string = 'Компания предлагает решение по управлению ресурсами Предприятия - корпоративный портал ИнтраСфера';
  product: string = 'ИнтраСфера'

  constructor() { }

  ngOnInit(): void {
  }

}
