<div class="header-wrap">
    <div class="header-content">
        <div class="logo-wrap" (click)="getHomePage()">
            <div class="logo"></div>
            <div class="logo-text"></div>
        </div>
        <nav class="main-nav">
            <ul>
                <li routerLink="/info" routerLinkActive="active">О компании </li>
                <li routerLink="/tech" routerLinkActive="active">Направления </li>
                <li routerLink="/research" routerLinkActive="active">Исследования и разработки</li>
                <li routerLink="/software" routerLinkActive="active">Программное обеспечение</li>
                <li routerLink="/contact" routerLinkActive="active-contacts">

                        Контактная информация


                </li>
            </ul>
            <div class="mini-contacts">
                <span>
                    <span>Email: </span>
                    <a href="mailto:tfinfo@teleformis.ru"> tfinfo@teleformis.ru </a>
                </span>
                <span>
                    <span>Тел.: </span>
                    +7 (495) 783 2056
                </span>
            </div>
        </nav>
        <nav class="alt-nav" *ngIf="isVisible" @slide>
            <ul (click)=toggleMenu()>
                <li routerLink="/info">О компании </li>
                <li routerLink="/tech">Направления </li>
                <li routerLink="/research">Исследования и разработки</li>
                <li routerLink="/software">Программное обеспечение</li>
                <li routerLink="/contact">Контактная информация</li>
            </ul>
        </nav>
        <div class="nav-icon" (click)=toggleMenu()></div>
    </div>
</div>
