import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-docu-sphere',
  templateUrl: './docu-sphere.component.html',
  styleUrls: ['./docu-sphere.component.scss']
})
export class DocuSphereComponent implements OnInit {

  header1: string = 'Доку-Сфера';
  header2: string = 'Система электронного документооборота';
  product: string = 'Доку-Сфера'

  constructor() { }

  ngOnInit(): void {
  }

}
