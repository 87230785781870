import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';

import { InfoComponent } from './components/info/info.component';

import { TechComponent } from './components/tech/tech.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NavComponent } from './components/nav/nav.component';
import { ResearchComponent } from './components/research/research.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { CornerComponent } from './components/corner/corner.component';
import { BiSphereComponent } from './web/bi-sphere/bi-sphere.component';
import { GuestComponent } from './web/guest/guest.component';
import { HeaderWebComponent } from './web/header-web/header-web.component';
import { IntraSphereComponent } from './web/intra-sphere/intra-sphere.component';
import { TrainingSphereComponent } from './web/training-sphere/training-sphere.component';
import { BiblioSphereComponent } from './web/biblio-sphere/biblio-sphere.component';
import { ProjectSphereComponent } from './web/project-sphere/project-sphere.component';
import { DocuSphereComponent } from './web/docu-sphere/docu-sphere.component';
import { StavkaComponent } from './web/stavka/stavka.component';
import { MaskaComponent } from './web/maska/maska.component';
import { ModulstavkaplusComponent } from './web/modulstavkaplus/modulstavkaplus.component';
import { SoftwareComponent } from './components/software/software.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InfoComponent,
    ResearchComponent,
    TechComponent,
    ContactsComponent,
    NavComponent,
    FooterComponent,
    HeaderComponent,
    CornerComponent,
    BiSphereComponent,
    GuestComponent,
    HeaderWebComponent,
    IntraSphereComponent,
    TrainingSphereComponent,
    BiblioSphereComponent,
    ProjectSphereComponent,
    DocuSphereComponent,
    StavkaComponent,
    MaskaComponent,
    ModulstavkaplusComponent,
    SoftwareComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
