import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactsComponent } from './components/contacts/contacts.component';
import { HomeComponent } from './components/home/home.component';
import { InfoComponent } from './components/info/info.component';
import { ResearchComponent } from './components/research/research.component';
import { TechComponent } from './components/tech/tech.component';
import { BiSphereComponent } from './web/bi-sphere/bi-sphere.component';
import { BiblioSphereComponent } from './web/biblio-sphere/biblio-sphere.component';
import { DocuSphereComponent } from './web/docu-sphere/docu-sphere.component';
import { GuestComponent } from './web/guest/guest.component';
import { IntraSphereComponent } from './web/intra-sphere/intra-sphere.component';
import { MaskaComponent } from './web/maska/maska.component';
import { ProjectSphereComponent } from './web/project-sphere/project-sphere.component';
import { StavkaComponent } from './web/stavka/stavka.component';
import { ModulstavkaplusComponent } from './web/modulstavkaplus/modulstavkaplus.component';
import { TrainingSphereComponent } from './web/training-sphere/training-sphere.component';
import { SoftwareComponent } from './components/software/software.component';

const routes: Routes = [

  { path: 'home', component: HomeComponent },
  { path: 'info', component: InfoComponent },
  { path: 'research', component: ResearchComponent },
  { path: 'software', component: SoftwareComponent },
  { path: 'tech', component: TechComponent },
  { path: 'contact', component: ContactsComponent },
  { path: 'web', children: [
    {path: 'guest', component: GuestComponent, children: [
      {path: 'bi-sphere', component: BiSphereComponent},
      {path: 'korporativnyj-portal', component: IntraSphereComponent},
      {path: 'trening-sphere', component: TrainingSphereComponent},
      {path: 'elektronnaya-biblioteka', component: BiblioSphereComponent},
      {path: 'sistema-upravleniya-proektami', component: ProjectSphereComponent},
      {path: 'docu-sphere', component: DocuSphereComponent},
      {path: 'sokb-stavka', component: StavkaComponent},
      {path: 'maska', component: MaskaComponent},
      {path: 'modulstavkaplus', component: ModulstavkaplusComponent}
    ]}
  ] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
