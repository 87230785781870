import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-sphere',
  templateUrl: './training-sphere.component.html',
  styleUrls: ['./training-sphere.component.scss']
})
export class TrainingSphereComponent implements OnInit {

  header1: string = 'Тренинг-Сфера ';
  header2: string = 'Система дистанционного обучения';
  product: string = 'Тренинг-Сфера'

  constructor() { }

  ngOnInit(): void {
  }

}
