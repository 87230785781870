import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-web',
  templateUrl: './header-web.component.html',
  styleUrls: ['./header-web.component.scss']
})
export class HeaderWebComponent implements OnInit {
  @Input() headerText1: string = '';
  @Input() headerText2: string = '';
  @Input() productName: string = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  getHomePage(){
    this.router.navigate(['/home']);   
  }
}
