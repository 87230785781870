import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements AfterViewInit {
  center: google.maps.LatLngLiteral = { lat: 55.720807, lng: 37.651198 }; 
  zoom = 17;
  markerPosition = { lat: 55.720807, lng: 37.651198 };

  feedbackForm: FormGroup;
  isFeedbackError: boolean = false;
  isSent: boolean = false;

  constructor(private http: HttpClient) {
    this.feedbackForm = new FormGroup({
      firstname: new FormControl(null, [Validators.required]),
      lastname: new FormControl(null, []),
      company: new FormControl(null, [Validators.required]),
      position: new FormControl(null, []),
      phone: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]+(?!.)/)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      content: new FormControl(null, []),

    })
  }

  ngAfterViewInit(): void {
    const anchor = document.querySelector('#top-anchor');
    anchor?.scrollIntoView();
  }

  onSubmit() {

    const postBody = {
      secret: "jfdjkfnvldfnlvj",
      response: "kshzflzkjdkjbvkjb",
      name: "Контакт: " + this.feedbackForm.value.firstname + " " + this.feedbackForm.value.lastname,
      email: "E-mail: " + this.feedbackForm.value.email,
      text: "Компания: " + this.feedbackForm.value.company
        + " Должность: " + this.feedbackForm.value.position
        + " Телефон: " + this.feedbackForm.value.phone
        + " Текст письма: " + this.feedbackForm.value.content,
    };

    if (this.feedbackForm.valid) {
        this.http.post<any>( 'https://teleformis.ru/feedback', postBody).subscribe((res)=>{      
        }, err =>{
          console.error(err);
        });
        this.feedbackForm.reset();
        this.isSent = true;
        let timer = setTimeout(()=>{
          this.isSent = false;
          clearTimeout(timer);
        }, 2500);
    } else {
      this.isFeedbackError = true;
      let timer = setTimeout(()=>{
        this.isFeedbackError = false;
        clearTimeout(timer);
      }, 2000);
    }
  }
}
