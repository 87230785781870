<div class="main-layout">
  <app-header-web [headerText1]="header1" [productName]="product"></app-header-web>
  <div class="content-layout">
    <div class="feature-layout">
      <div class="block">
        <div class="content">Модуль видеоаналитики Ставка+:
          <ul>
            <li>Позволяет автоматизировать распознавание номерных знаков проезжающего автомобиля.  В качестве основы модуля используются нейросети, применяемые для анализа видеопотоков и видеозаписей, получаемых от установленных на пунктах пропуска видеокамер. </li>
            <li>Модуль осуществляет фиксацию номерного знака на снимке, передачу его и распознанного номера в подсистему ядра СУД ЭП.</li>
            <li>Модуль позволяет распознавать автомобильные номера РФ, РБ, дипломатические, армейские номера, номера МВД.</li>
            <li>В качестве источника данных используется камера Hikvision DS-2CD2623G0-IZS , производящая съемку в видимом диапазоне.</li>
            <li>Стоимость 324 000 рублей с НДС.</li>
          </ul>
        </div>
      </div>
      <div class="block">
        <div class="title">Руководства</div>
        <div class="content">
          <a href="../../../assets/docs/modulstavkaplus/Description.docx" download="Description.docx">Краткое описание</a><br>
          <a href="../../../assets/docs/modulstavkaplus/User_guide.docx" download="User_guide.docx">Руководство пользователя</a><br>
          <a href="../../../assets/docs/modulstavkaplus/Functional_characteristics.docx" download="Functional_characteristics.docx">Функциональные характеристики</a><br>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>

