<div class="main-layout">
    <app-header-web [headerText1]="header1" [headerText2]="header2" [productName]="product"></app-header-web>
    <div class="content-layout">
        <div class="feature-layout">

            <div class="intro"><strong>Программный продукт Тренинг-Сфера </strong> внесен в состав <a
                    href="https://reestr.digital.gov.ru/reestr/87674/" target="__blank">Реестра отечественного ПО</a> на
                основании приказа Минкомсвязи России от 06 сентября 2016 года.</div>
            <div class="block">
                <div class="title"> Тренинг-Сфера решает следующие задачи</div>
                <div class="content">
                    <ul>
                        <li>обеспечение полного цикла образовательных процессов в организации;</li>
                        <li>формирование поэтапных процессов обучения в виде совокупности курсов и разделов;</li>
                        <li>контроль результатов обучения, а также сроков сдачи заданий и тестов;</li>
                        <li>формирование мультимедийной среды обучения (видеоконференции, вебинары, чаты и т.д.);</li>
                        <li>обеспечение обратной связи между обучающимися и тренерами;</li>
                        <li>как итог, сокращение затрат на создание образовательных бизнес-процессов, а также содержание
                            служб консалтинга.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <div class="title"> Функциональные возможности</div>
                <div class="content">
                    <ul>
                        <li>Формирование курсов обучения;</li>
                        <li>Создание подразделов курса;</li>
                        <li>Назначение преподавателей и тренеров на разделы курса;</li>
                        <li>Наполнение разделов (лекции, опросы, тесты задания и т.д.);</li>
                        <li>Билетная система выдачи заданий - случайный порядок вопросов и заданий для каждого
                            слушателя;</li>
                        <li>Автоматическая оценка тестов и опросов;</li>
                        <li>Контроль сроков выполнения заданий;</li>
                        <li>Проставление баллов как по единичным заданиям, так и по разделам курса;</li>
                        <li>Построение отчетов по успеваемости;</li>
                        <li>Проведение и планирование видеоконференций и вебинаров с возможностью демонстрации экрана,
                            публикции файлов, предоставление общей демонстрационной доски (white-board);</li>
                        <li>Личный кабинет пользователя - личный календарь, избранное, задания в работе, электронный
                            дневник;</li>
                        <li>Обратная связь с преподавателями;</li>
                        <li>Создание ситуационной онлайн помощи сотрудникам - планов-схем поведения в определенных
                            ситуациях, с отображением возможных результатов действий.</li>
                    </ul>
                </div>
            </div>

            <div class="block">
                <div class="title">Защита информации</div>
                <div class="content">Предлагаемое решение подготовлено к сертификации ФСТЭК и ФСБ для работы с
                    конфиденциальной информацией и информацией составляющей государственную тайну по классам
                    защищенности 1Г и 1Б.</div>
            </div>
            <div class="block">
                <div class="title">Предлагаемая технологическая платформа</div>
                <div class="content"><strong>Тренинг-Сфера </strong>разработана с применением свободно распространяемых программных продуктов с открытым исходным кодом, являющихся лидерами в своих направлениях. В качестве технологической платформы была выбрана Java EE. Данная технологическая платформа позволяет Тренинг-Сфера функционировать на любой операционной системе, в том числе и на сертифицированной операционной системе Astra Linux Special Edition.</div>
            </div>

            <div class="block">
                <div class="title">Руководства</div>
                <div class="content">
                    <a href="../../../assets/docs/training-sphere/ts-sopr-users.pdf" download="ts-sopr-users.pdf">Регламент по сопровождению пользователей</a><br>
                    <a href="../../../assets/docs/training-sphere/ts-ruk-adm.pdf" download="ts-ruk-adm.pdf">Руководство администратора</a><br>
                    <a href="../../../assets/docs/training-sphere/ts-ruk_oper.pdf" download="ts-ruk_oper.pdf">Руководство оператора</a>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>