import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss']
})
export class ResearchComponent implements AfterViewInit {

  header1: string = 'Новые знания и технологии ';
  header2: string = 'для эффективной трансформации ';
  header3: string = 'идей в решения';

  constructor() { }

  ngAfterViewInit(): void {
    const anchor = document.querySelector('#top-anchor');
    anchor?.scrollIntoView();
  }

}
