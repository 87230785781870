import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { slideAnimation } from 'src/app/shared/utils';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'], 
  animations:[ slideAnimation ]
})
export class NavComponent implements OnInit {

  isVisible: boolean = false;
  constructor(private router: Router) {

  }
  ngOnInit(): void {
    
  }
  toggleMenu() {
    this.isVisible = !this.isVisible;
  }

  getHomePage(){
    this.router.navigate(['/home']);   
  }

}
