import { Component, Input, OnInit } from '@angular/core';
import { headerAnimation, mobileAnimation } from 'src/app/shared/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'], 
  animations:[headerAnimation, mobileAnimation]
})
export class HeaderComponent implements OnInit {

  @Input() headerText1: string = '';
  @Input() headerText2: string = '';
  @Input() headerText3: string = '';

  @Input() isSecondBold: boolean = false;

  isMobile: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (window.screen.width < 1440) this.isMobile = true;
  }

}
