<div class="header-layout">
    <div class="main-title">
        <div class="text-wrap">
            <div class="text" @headerAppear *ngIf="!isMobile">
                <br>
               <span class="bold"> {{headerText1}}</span> <br>
                <span [class.bold]="isSecondBold">{{headerText2}} </span><br>
                {{headerText3}} 
                 <br>&nbsp;
            </div>
            <ng-container *ngIf="isMobile">
                <div class="text" @mobile>
                    <span class="bold"> {{headerText1}}</span> <br>
                     <span [class.bold]="isSecondBold">{{headerText2}} </span><br>
                     {{headerText3}} 
                     
                 </div>
            </ng-container>
        </div>
    </div>
</div>
