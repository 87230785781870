import { animate, keyframes, style, transition, trigger } from "@angular/animations";

export const slideAnimation = trigger('slide', [
  transition(':enter', [
    style({ transform: 'translateX(-500px)' }),
    animate('600ms ease-out', style({ transform: 'translateX(0)' })),
  ]),
  transition(':leave', [
    animate('600ms ease-out', style({ transform: 'translateX(-500px)' }))
  ])
]);

export const appearAnimation = trigger('appear', [
  transition(':enter', [
    style({ transform: 'translateY(-100px)', opacity: 0 }),
    animate('800ms ease-out', style({ transform: 'translateY(0)', opacity: 1})),
  ])
]);

export const headerAnimation = trigger('headerAppear', [
  transition(':enter', [
    style({ transform: 'translateX(380px)', opacity: 0 }),
    animate('1000ms ease-out', style({ transform: 'translateX(0)', opacity: 1})),
  ])
]);
export const mobileAnimation = trigger('mobile', [
  transition(':enter', [
    style({ transform: 'translateX(8vw)', opacity: 0 }),
    animate('800ms ease-out', style({ transform: 'translateX(0)', opacity: 1})),
  ])
]);

export const fadeAnimation = trigger('fade', [
  transition(':enter', [
    
    animate('2000ms ease-in-out', keyframes([
      style({opacity: 0, offset: 0}),
      style({opacity: 0, offset: 0.4}),
      style({opacity: 1, offset: 1}),
    ])),
  ])
]);