<div class="main-layout">
    <app-header-web [headerText1]="header1" [headerText2]="header2" [productName]="product"></app-header-web>
    <div class="content-layout">
        <div class="feature-layout">

            <div class="intro"><strong>Программный продукт Проект-Сфера </strong> внесен в состав <a
                    href="https://reestr.digital.gov.ru/reestr/88046/" target="__blank">Реестра отечественного ПО </a>
                на основании приказа Минкомсвязи России от 06 сентября 2016 года.
            </div>
            <div class="block">
                <div class="title">Система управления проектами и программами</div>
                <div class="content">
                     <strong>Проект-Сфера </strong> разработана с учетом потребностей, особенностей и приоритетов российских пользователей. Система изначально спроектирована для использования в качестве корпоративной системы для управления проектами, портфелями проектов и программами, содержит уникальные средства для создания и использования в проектах всевозможных корпоративных стандартов и справочников.
                </div>
            </div>

            <div class="block">
                <div class="title"> Проект-Сфера помогает решить следующие основные задачи</div>
                <div class="content">
                    <ul>
                        <li>обосновать целесообразность инвестиций;</li>
                        <li>разработать оптимальную схему финансирования работ, поставок материалов и оборудования;</li>
                        <li>составить план работ, включающий сроки исполнения работ, потребление ресурсов, необходимые затраты;</li>
                        <li>проанализировать проектные риски;</li>
                        <li>обеспечить эффективное взаимодействие участников проекта;</li>
                        <li>эффективно контролировать исполнение составленного плана;</li>
                        <li>анализировать отклонения фактического хода выполнения работ от запланированного и своевременно и обоснованно корректировать плановые показатели;</li>
                        <li>моделировать управленческие воздействия на информационных моделях проектов и принимать обоснованные управленческие решения;</li>
                        <li>вести архивы проектов и анализировать опыт их реализации, который может быть использован в других проектах;</li>
                        <li>формировать отчетность.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <div class="title"> Преимущества от внедрения</div>
                <div class="content">
                    <ul>
                        <li>сокращение времени формирования сводного инвестиционного портфеля;</li>
                        <li>снижение риска принятия ошибочных решений по портфелю вследствие низкой достоверности или оперативности данных;</li>
                        <li>минимизация риска неполучения планового эффекта вследствие рассинхронизации проектов в программе;</li>
                        <li>сокращение длительности и трудоемкости формирования отчетности по портфелю проектов;</li>
                        <li>снижение трудоемкости на функцию, повышение достоверности и оперативности в получении данных;</li>
                        <li>развитие уровня компетенций в области управления проектами.</li>
                    </ul>
                </div>
            </div>

            <div class="block">
                <div class="title">Особенности и технические характеристики</div>
                <div class="content">
                    <ul>
                        <li>пользователи взаимодействуют с системой через браузер («тонкий клиент»);</li>
                        <li>существует разграничение полномочий пользователей (роли, группы);</li>
                        <li>предусмотрен аудит действий пользователей;</li>
                        <li>для авторизации в системе предусмотрена интеграция с Active Directory;</li>
                        <li>система интегрирована с Microsoft Project (загрузка и выгрузка календарного плана, ресурсов);</li>
                        <li>используются СУБД PostgreSQL и сервер приложений Glassfish с открытым исходным кодом и не требующими покупки лицензии.</li>
                    </ul>
                </div>
            </div>

            <div class="block">
                <div class="title">Руководства</div>
                <div class="content">
                    <a href="../../../assets/docs/project-sphere/prs-reg-sopr-user.pdf" download="prs-reg-sopr-user.pdf">Регламент по сопровождению пользователей</a><br>
                    <a href="../../../assets/docs/project-sphere/prs-reg-admin.pdf" download="prs-reg-admin.pdf">Руководство администратора</a><br>
                    <a href="../../../assets/docs/project-sphere/prs-reg-exp.pdf" download="prs-reg-exp.pdf">Руководство оператора</a>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>