<div class="main-layout">
    <app-header-web [headerText1]="header1" [headerText2]="header2" [productName]="product"></app-header-web>
    <div class="content-layout">
        <div class="feature-layout">

            <div class="intro"><strong>Программный продукт Доку-Сфера </strong> внесен в состав <a
                    href="https://reestr.digital.gov.ru/reestr/89484/" target="__blank">Реестра отечественного ПО </a>
                на основании приказа Минкомсвязи России от 29 сентября 2016 года.
            </div>

            <div class="block">
                <div class="title"> Доку-Сфера решает следующие  задачи</div>
                <div class="content">
                    <ul>
                        <li>обеспечение централизованного и безопасного хранения документов организации;</li>
                        <li>предоставление сервисов полнотекстового поиска как по атрибутам, так и по содержанию документа;</li>
                        <li>организация движения документов между подразделениями, группами сотрудников или отдельными сотрудниками;</li>
                        <li>автоматизация и повышение качества контроля исполнительской дисциплины посредством установки и автоматического мониторинга сроков прохождения электронных документов;</li>
                        <li>автоматизация создания бизнес-процессов, регулирующих и управляющих маршрутами движения электронных документов;</li>
                        <li>обеспечение разграничения прав доступа сотрудников к информационным ресурсам предприятия, как на уровне групп документов, так и на уровне отдельных документов.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <div class="title">Функциональные возможности</div>
                <div class="content">
                    <ul>
                        <li>организация централизованного хранения документов предприятия в едином информационном пространстве;</li>
                        <li>создание поискового аппарата по всему фонду электронного архива, позволяющего пользователю проводить многоаспектный поиск как по атрибутам, так и по содержанию документов;</li>
                        <li>автоматический контроль за сроками хранения документов;</li>
                        <li>безопасный доступ к документам сотрудников предприятия, а также сотрудников удаленных филиалов и подразделений посредством разграничения прав доступа;</li>
                        <li>удешевление процессов хранения, поиска, обработки документов предприятия;</li>
                        <li>создание бизнес-процессов, управляющих маршрутами и сроками прохождения документов между подразделениями предприятия и отдельными сотрудниками;</li>
                        <li>создание справочников, описывающих правила обработки для каждого типа и вида документа;</li>
                        <li>автоматическая рассылка электронных уведомлений о движении документа, или изменении его состояния всем заинтересованным лицам;</li>
                        <li>автоматическая привязка исходящих документов к входящим – построение цепочек документов;</li>
                        <li>автоматическая регистрация входящих документов из внешних ресурсов, например, из электронной почты;</li>
                        <li>поддержка бумажных документов предприятия – регистрация бумажных документов, создание метаданных (карточек), отслеживание, и контроль сроков нахождения бумажных документов в подразделениях и у отдельных сотрудников предприятия;</li>
                        <li>контроль сроков прохождения документа – для каждого этапа исполнения документа предусмотрена возможность установления срока выполнения;</li>
                        <li>автоматическая рассылка электронных уведомлений о превышении сроков выполнения документа, визуальное выделение просроченных документов, а также документов с истекающими сроками, для каждого типа и вида документа предусмотрена настройка момента уведомления о истечении срока выполнения документа;</li>
                        <li>учет и анализ рабочего времени сотрудников - автоматическое добавление записей в отчеты занятости при выполнении каждой задачи исполнителем;</li>
                        <li>личный кабинет пользователя – при назначении задачи сотруднику, в его личном кабинете, в модуле «календарь» автоматически создается метка на дате, когда задание должно быть выполнено;</li>
                        <li>электронная приемная руководства предприятия – любой сотрудник может записаться на прием к руководителю, выбрав время приема из настроенного расписания приема, а также указав тему приема. Также любой сотрудник может задать вопрос руководителю в виде либо приватного, либо публичного электронного сообщения;</li>
                        <li>управление рабочим временем сотрудников предприятия – мониторинг прихода/ухода на работу сотрудников предприятия, составление гибких графиков рабочего времени сотрудников.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <div class="title">Защита информации</div>
                <div class="content">Предлагаемое решение подготовлено к сертификации ФСТЭК и ФСБ для работы с
                    конфиденциальной информацией и информацией составляющей государственную тайну по классам
                    защищенности 1Г и 1Б.</div>
            </div>
            <div class="block">
                <div class="title">Предлагаемая технологическая платформа</div>
                <div class="content"><strong>Доку-Сфера</strong> разработана с применением свободно распространяемых программных продуктов
                    с открытым исходным кодом, являющихся лидерами в своих направлениях. В качестве технологической
                    платформы была выбрана Java EE. Данная технологическая платформа позволяет <strong>Доку-Сфера </strong> функционировать
                    на любой операционной системе, в том числе и на сертифицированной операционной системе Astra Linux
                    Special Edition.</div>
            </div>
            <div class="block">
                <div class="title">Руководства</div>
                <div class="content">
                    <a href="../../../assets/docs/docu-sphere/docu-reg-user.pdf" download="docu-reg-user.pdf">Регламент по сопровождению пользователей</a><br>
                    <a href="../../../assets/docs/docu-sphere/docu-reg-admin.pdf" download="docu-reg-admin.pdf">Руководство администратора</a><br>
                    <a href="../../../assets/docs/docu-sphere/docu-reg-oper.pdf" download="docu-reg-sopr.pdf">Руководство оператора</a>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
