<div class="footer">
    <div class="footer-layout">
        <div class="contacts">
            <span class="address">115114, Москва, Дербеневская наб., д. 11, корп. А &nbsp; &nbsp;</span>
            Тел.: +7 (495) 783 2056,&nbsp;
            Факс.: +7 (495) 909 2830,&nbsp; 
            Email: <a href="mailto:tfinfo@teleformis.ru">tfinfo@teleformis.ru</a> 
        </div>
    </div>
</div>
