<!-- <app-nav></app-nav> -->
<div class="main-layout">
    <app-nav id="top-anchor"></app-nav>
    <app-header [headerText1]="header1" [headerText2]="header2" [headerText3]="header3" [isSecondBold]="true">
    </app-header>
    <div class="content-layout">
        <div class="intro">
            <div class="intro-content">
                <app-corner></app-corner>
                <div class="title">Направления</div>
                <div class="content">Компания "Телеформ ИС" активно развивает и адаптирует информационные технологии и
                    практики, помогающие российским организациям максимально эффектно достигать поставленных целей и
                    адекватно реагировать на глобальные вызовы, оптимально используя открывающиеся возможности. Выбор
                    перспективных направлений исследований и разработок "Телеформ ИС" воплощается в спектре практических
                    решений, становящихся важным инструментом для обеспечения конкурентных преимуществ клиентов и
                    партнеров компании. </div>

            </div>

        </div>
        <div class="sub-header">
            <div class="content">Приоритеты 2022</div>
        </div>
        <div class="feature-layout">
            <div class="features">
                <div class="block">
                    <div>
                        Разработка систем виртуальной интеграции данных и организации их высокопроизводительной
                        обработки с использованием технологий баз данных и гридов данных в памяти (In-Memory Data Base,
                        IMDB; In-Memory Data Grid, IMDG);
                    </div>
                    <div>
                        Разработка систем управления данными на основе технологий обработки Big Data и искусственного
                        интеллекта;
                    </div>
                    <div>
                        Разработка программного обеспечения цифровизации производственных и управленческих процессов в
                        сельском хозяйстве;
                    </div>
                    <div>
                        Разработка платформ для видеоконференц-связи и дистанционного обучения;
                    </div>
                    <div>
                        Разработка программного обеспечения систем дополненной и виртуальной реальности (AR и VR);
                    </div>
                </div>
                <div class="block">
                    <div>
                        Разработка компонентов систем управления робототехническими комплексами, в том числе модулей
                        технического зрения;
                    </div>
                    <div>
                        Разработка систем нейросетевого анализа изображений;
                    </div>
                    <div>
                        Разработка систем имитационного моделирования;
                    </div>
                    <div>
                        Разработка систем оперативной, в т. ч. аналитической, обработки больших данных в локальных и
                        территориально-распределенных информационно-вычислительных и управляющих комплексах;
                    </div>
                    <div>
                        Разработка приложений для медицинской диагностики и поддержки принятия решений медицинскими
                        работниками;
                    </div>

                </div>
                <div class="block">
                    <div>
                        Разработка систем управления распределенными комплексами контрольно-измерительного оборудования
                        в соответствии с концепциями интернета вещей (Internet of Things, IoT) и промышленного интернета
                        вещей (Industrial IoT, IioT);
                    </div>
                    <div>
                        Разработка систем управления процессами с использованием средств визуального проектирования
                        процессов, позволяющих получать описания процессов на стандартных языках типа BPMN2 и
                        допускающих исполнение программами, работающими в составе комплексов ПО различных типов АС;
                    </div>
                    <div>
                        Разработка инструментов анализа и рефакторинга кода компонентов систем комплексов ПО с открытым
                        исходным кодом с целью обеспечения возможности их многократного использования в проектах
                        создания промышленных АС и АСЗИ.
                    </div>
                </div>
            </div>

        </div>
    </div>
    <app-footer></app-footer>
</div>
