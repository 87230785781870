<div class="main-layout">
    <app-header-web [headerText1]="header1" [headerText2]="header2" [productName]="product"></app-header-web>
    <div class="content-layout">
        <div class="feature-layout">

            <div class="intro"><strong>Программный продукт ИнтраСфера </strong> внесен в состав <a
                    href="https://reestr.digital.gov.ru/reestr/75864/" target="__blank">Реестра отечественного ПО</a> на
                основании приказа Минкомсвязи России от 19 мая 2016 года.</div>
            <div class="block">
                <div class="content">
                    ИнтраСфера представляет собой веб-приложение, предназначенное для управления ресурсами предприятия,
                    основной задачей которой является формирование единого информационного пространства внутри
                    организации и автоматизации ее бизнес-процессов.
                </div>
                <div class="content">Портал формирует единое пространство для хранения разнородных данных предприятия и
                    доступа к ним, повышает оперативность управления предприятием и улучшает эффективность социального
                    сотрудничества между работниками предприятия.</div>
                <div class="content">ИнтраСфера представляет собой эффективное решение автоматизации управления
                    предприятием, которое может быть использовано как в небольшой компании, так и в масштабах больших
                    предприятий.</div>
            </div>
            <div class="block">
                <div class="title"> Функциональные возможности системы</div>
                <div class="content">
                    <ul>
                        <li>ведение организационной структурой предприятия;</li>
                        <li>ведение календарей мероприятий;</li>
                        <li>публикация вакансии предприятия;</li>
                        <li>публикация доски почета;</li>
                        <li>проведение блиц-опросов;</li>
                        <li>ведение форума, обмен сообщениями;</li>
                        <li>работа в личном кабинете;</li>
                        <li>ведение электронной приемной руководства;</li>
                        <li>ведение графика отпусков;</li>
                        <li>встроенная электронная библиотека;</li>
                        <li>возможность интеграция с внешними системами;</li>
                        <li>интеграция с LDAP.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <div class="content">ИнтраСфера имеет дружественный пользовательский интерфейс, который обеспечивает наглядное, интуитивно понятное представление размещенной на портале информации, а также быстрый и логичный переход к разделам и страницам.</div>
            </div>
            <div class="block">
                <div class="title">Руководства</div>
                <div class="content">
                    <a href="../../../assets/docs/intra-sphere/IntraSphere_users-regl.pdf" download="users-regl.pdf">Регламент по сопровождению пользователей</a><br>
                    <a href="../../../assets/docs/intra-sphere/IntraSphere_admin-guide.pdf" download="IntraSphere_admin-guide.pdf">Руководство администратора</a><br>
                    <a href="../../../assets/docs/intra-sphere/IntraSphere_oper-guide.pdf" download="users-regl.pdf">Руководство оператора</a>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>