import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stavka',
  templateUrl: './stavka.component.html',
  styleUrls: ['./stavka.component.scss']
})
export class StavkaComponent implements OnInit {

  header1: string = 'Ставка+';
  header2: string = 'Система обеспечения комплексной безопасности';
  product: string = 'Ставка+'

  constructor() { }

  ngOnInit(): void {
  }

}
