import { AfterViewInit, Component} from '@angular/core';
@Component({
  selector: 'app-tech',
  templateUrl: './tech.component.html',
  styleUrls: ['./tech.component.scss']
})
export class TechComponent implements AfterViewInit {

  header1: string = 'Поддержка процессов';
  header2: string = 'технологических преобразований,';
  header3: string = 'востребованных рынком';

  constructor() {}

  ngAfterViewInit(): void {
    const anchor = document.querySelector('#top-anchor');
    anchor?.scrollIntoView();
  }

}
