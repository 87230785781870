<div class="main-layout">
    <app-header-web [headerText1]="header1" [headerText2]="header2" [productName]="product"></app-header-web>
    <div class="content-layout">
        <div class="feature-layout">

            <div class="intro"><strong>Программный продукт "Ставка+" и его составные модули</strong> внесены в состав <a
                    href="https://reestr.digital.gov.ru/reestr/139088/" target="__blank">Реестра отечественного ПО </a>
                на основании приказа Минкомсвязи России от 30 ноября 2018 года.
            </div>
            <div class="block">
                <div class="title">Система обеспечения комплексной безопасности «Ставка+»</div>
                <div class="content">Современные технические средства и системы предоставляют широкие возможности по защите информации и имущества объектов различного профиля. Наибольшая эффективность обеспечивается при объединении разнородных систем в единый комплекс анализа угроз и поддержки принятия управленческих решений. Это позволяет добиться четкой координации функционирования всех систем и сил охраны, сократить численность персонала, уменьшить влияние человеческого фактора, в целом снизить расходы на обеспечение безопасности объекта. Именно для объединения разнородных систем и достижения наивысшей эффективности по безопасности объектов предназначена система обеспечения комплексной безопасности «Ставка+».<br><br>
                  Минимальная стоимость <b>Ставка+</b> 46 000 000 руб без НДС<br>
                  Минимальная поставка Ядро <b>Ставка+</b>
                </div>
            </div>
            <div class="block">
                <div class="title">Цели и назначение</div>
                <div class="content">
                    <ul>
                        <li>объединение различных систем обеспечения безопасности объекта в единый комплекс с целью управления ими как единой системой;</li>
                        <li>комплексный анализ информации и построение на его основе системы поддержки принятия решений для всех ответственных служб с целью обеспечения максимально скоординированных действий по устранению угроз безопасности;</li>
                        <li>создание унифицированных автоматизированных рабочих мест (АРМ) дежурной смены с интуитивно понятным и максимально информативным интерфейсом;</li>
                        <li>объединение в единый комплекс территориально разнесенных объектов.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <div class="title"> Функциональные возможности</div>
                <div class="content">
                    <ul>
                        <li>интеграция с оборудованием и системами различных производителей;</li>
                        <li>интеграция с сопредельными бизнес-системами (кадровая, управления  жизнеобеспечением объекта, учета рабочего времени и пр.);</li>
                        <li>корректировка логики управления на основе детального разбора отработанных угроз безопасности и соответствующих действий персонала;</li>
                        <li>многофункциональная система формирования типовых отчетов;</li>
                        <li>устойчивая работа при возможности многократном увеличении объема обрабатываемой информации;</li>
                        <li>развертывание как в рамках одного локального объекта, так и объединение территориально разнесенных объектов в единый комплекс обеспечения безопасности, с возможностью адаптивного назначения изменения центрального узла системы.</li>
                    </ul>
                </div>
            </div>
            <div class="block">
                <img alt="Here is a picture" style="margin: 25px 0;" src="../../../assets/images/stavka.png">
            </div>
            <div class="block">
                <div class="title">Предлагаемая технологическая платформа</div>
                <div class="content">Система обеспечения комплексной безопасности «Ставка+» разработана на отечественной программной платформе и операционной системе AstraLinux, имеет облачную (cloud) структуру и виртуализацию, построенную на множестве серверов, работающих как одно целое. Система может быть сертифицирована для работы с информацией, составляющей государственную тайну</div>
            </div>
          <div class="block">
            <div class="title">Программные модули "Ставка+"</div>
            <div class="content">
              <ul>
                <li><strong>Ядро Ставка+</strong><br>
                    <strong>Ядро Ставка+ вер.2</strong><br>
                  Основной центральный компонент системы Ставка+, реализует общесистемные и функциональные  взаимодействия остальных модулей комплекса Ставка+ внутри системы Ставка+. Отвечает за установку и настройку дополнительных модулей системы Ставка+.<br>
                  Сборка: <br>
                  >cd /home/user/core/dn<br>
                  >make<br>
                  Запуск:<br>
                  >cd /home/user/core<br>
                  >python core_run.py<br>
                  Настройка: осуществляется путем внесения изменений в конфигурационный файл stavka_core.conf<br>
                  Стоимость: 46 000 000 рублей без НДС
                </li>
                <li><strong>Система Контроля Модулей Интеграции Ставка+</strong><br>
                  Системный модуль информационной безопасности, обеспечивающий целостность интегрируемых информационных потоков.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 35 000 000 рублей без НДС
                </li>
                <li><strong>Модуль Планировщик Охраны Ставка+</strong><br>
                  Функциональный модуль ССКУ Ставка+, реализующий настройку режимов допуска на охраняемые объекты и взятие под охрану охраняемых объектов.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 21 000 000 рублей без НДС
                </li>
                <li><strong>Модуль взаимодействия БД Ставка+</strong><br>Системный модуль, реализующий открытие, взаимодействие и оптимизацию многопоточных запросов при обращениях к базе данных, а также обработку ответов от базы данных с учетом требований информационной безопасности системы.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 3 000 000 рублей без НДС
                </li>
                <li><strong>Модуль микроядро СКУД Ставка+</strong><br>
                  Системный модуль, реализующий набор функциональных микропрограмм для работы с различными  системами  контроля и управления доступом.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 17 000 000 рублей без НДС
                </li>
                <li><strong>Модуль микроядро Охрана Ставка+</strong><br>
                  Системный модуль, реализующий набор функциональных микропрограмм для работы с различными  системами охранно-тревожной и пожарной сигнализации.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 19 000 000 рублей без НДС
                </li>
                <li><strong>АРМ Охраны Ставка+</strong><br>
                  Функциональный модуль, реализующий функциональность Автоматизированного рабочего места оператора, контролирующего состояние охраняемых объектов.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 18 000 000 рублей без НДС
                </li>
                <li><strong>АРМ СКУД Ставка+</strong><br>
                  Функциональный модуль, реализующий функциональность Автоматизированного рабочего места оператора, обеспечивающего работу систем контроля доступом на охраняемый объект.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 20 000 000 рублей без НДС
                </li>
                <li><strong>АРМ Ставка+</strong><br>
                  Модуль, реализующий функциональность автоматизированного рабочего место, предназначенного для управления физическим оборудованием, на котором развернута Система.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 15 000 000 рублей без НДС
                </li>
                <li><strong>Модуль Бизнес Логики Ставка+</strong><br>
                  Специализированное серверное приложение, реализующее бизнес-логику обработки сценариев клиентской части.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 30 000 000 рублей без НДС
                </li>
                <li><strong>Модуль контроля АРМов СТАВКА+</strong><br>
                  Системный модуль информационной безопасности, обеспечивающий целостность АРМ.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 18 000 000 рублей без НДС
                </li>
                <li><strong>Модуль низкоуровневого сетевого контроля Ставка+</strong><br>
                  Системный модуль, реализующий мониторинг элементов систем, входящих в интегрированный комплекс технических средств охраны.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 18 000 000 рублей без НДС
                </li>
                <li><strong>Модуль резервирования</strong><br>
                  Системный модуль, реализующий резервное копирование данных в различных режимах функционирования.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 20 000 000 рублей без НДС
                </li>
                <li><strong>Модуль репликации данных БД Ставка+</strong><br>
                  Системный модуль, обеспечивающий горячее резервирование данных и репликацию данных между распределенными узлами системы.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 20 000 000 рублей без НДС
                </li>
                <li><strong>Модуль микроядро Видео Ставка+</strong><br>
                  Системный модуль, реализующий функциональные микропрограммы для работы с системой оптико-электронного наблюдения.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 20 000 000 рублей без НДС
                </li>
                <li><strong>АРМ Старшего Смены Ставка+</strong><br>
                  Автоматизированное рабочее место, предоставляющее функциональность наблюдения, контроля и управления техническими средствами охраны объекта.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 18 000 000 рублей без НДС
                </li>
                <li><strong>АРМ Инженера Ставка+</strong><br>
                  Автоматизированное рабочее место, предоставляющее функциональность для получения объективной информации о состоянии интегрированного комплекса технических средств охраны.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 19 000 000 рублей без НДС
                </li>
                <li><strong>Модуль расширения ВИДЕО для АРМ Ставка+</strong><br>
                  Системный модуль, реализующий функциональность для работы с модулем видео-аналитики системы оптико-электронного наблюдения.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 15 000 000 рублей без НДС
                </li>
                <li><strong>Модуль охраны военного управления Ставка+</strong><br>
                  Модуль охраны военного управления «Ставка+» является составной частью ССКУ «Ставка+».<br>
                  Модуль предназначен для автоматизированного решения задач управления процессами охраны внутренних выделенных помещений в рамках одного военного управления. А так же получении информации о текущем состоянии технических средств объектов охраны.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 17 000 000 рублей без НДС
                </li>
                <li><strong>Модуль видео военного управления Ставка+</strong><br>
                  Модуль предназначен для автоматизированного решения задач управления процессами контроля за внутренними выделенными помещениями в рамках одного военного управления и контроля входов в корпуса. А так же получении информации о текущем состоянии технических средств видеонаблюдения в рамках военного управления.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 15 000 000 рублей без НДС
                </li>
                <li><strong>Модуль интеграции ПЭК Ставка+</strong><br>
                  Модуль интеграции ПЭК предназначен для интеграции Персональной электронной карточки военнослужащего в информационную среду ССКУ «Ставка+».<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 7 000 000 рублей без НДС
                </li>
                <li><strong>Модуль Биосмарт Ставка+</strong><br>
                  Модуль предназначен для интеграции СКУД «Биосмарт» с ССКУ «Ставка+» и для ведение базы биометрических данных вен ладони непосредственно с АРМ-ов ССКУ «Ставка +» Модуль обеспечивает полный цикла выдачи постоянных, временных и разовых пропусков с АРМ-ов ССКУ «Ставка+», работающих под управлением операционной системы Astra-Linux с учетом необходимости ввода и актуализации биометрических данных вен ладони.<br>
                  Модуль позволяет проверять биометрических данных вен ладони на турникетах и на шлюзах.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 46 000 000 рублей без НДС
                </li>
                <li><strong>Модуль центра управления Ставка+</strong><br>
                  Модуль предназначен для автоматизированного решения задач управления процессами контроля. В том числе контроля за обстановкой на периметрах объекта, административной территории, КПП, во внутренних помещениях объекта, получение информации из интегрированных систем комплекса технических средств охраны, а также получения информации из инженерных систем отвечающих за обеспечение жизнедеятельности объекта.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 35 000 000 рублей без НДС
                </li>
                <li><strong>Модуль пропускного режима</strong><br>
                  Модуль предназначен для выпуска разовых и временных пропусков.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 20 000 000 рублей без НДС
                </li>
                <li><strong>Модуль КПП Ставка+</strong><br>
                  Модуль обеспечивает информационный обмен между центром обработки информации ПАК Ставка+ и серверной частью установленной на отдельном КПП. Модуль обеспечивает автономную работу КПП на текущих данных в случае обрыва  связи и сбоях в центре обработки информации.<br>
                  Установка: посредством механизма установки системы Ставка+<br>
                  Стоимость: 10 000 000 рублей без НДС
                </li>
              </ul>
            </div>
          </div>
          <div class="block">
                <div class="title">Руководства</div>
                <div class="content">
                    <a href="../../../assets/docs/stavka/Руководство_пользователя.docx" download="Руководство_пользователя.docx">Руководство пользователя</a><br>
                    <a href="../../../assets/docs/stavka/Регламент_сопровождения.docx" download="Регламент_сопровождения.docx">Регламент сопровождения</a><br>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
