import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modulstavkaplus',
  templateUrl: './modulstavkaplus.component.html',
  styleUrls: ['./modulstavkaplus.component.scss']
})
export class ModulstavkaplusComponent implements OnInit {

  header1: string = 'Модуль видеоаналитики «Ставка+»';
  product: string = 'Модуль видеоаналитики «Ставка+»'


  constructor() { }

  ngOnInit(): void {
  }

}
